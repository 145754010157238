import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';

@Component({
  selector: 'jbd-app-section-toggle',
  templateUrl: './section-toggle.component.html',
  styleUrls: ['./section-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdSharedSectionToggleComponent {
  @Input() public title!: string;
  public isExpanded = true;
}
