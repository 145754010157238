import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  Routes,
  UrlSegment,
} from '@angular/router';
import { JbdCoreAuthGuard } from '@core/services/auth/auth.guard';
import { JbdUiLayoutPageComponent } from '@ui/compositions/layout/page/page.component';
import { JbdAccountActivateComponent } from './account/activate/activate.component';
import { JbdAccountLoginComponent } from './account/login/login.component';
import { JbdAccountPageComponent } from './account/page/page.component';
import { JbdAccountPasswordForgottenComponent } from './account/password-forgotten/password-forgotten.component';
import { JbdAccountRegisterComponent } from './account/register/register.component';
import { inject } from '@angular/core';
import { JbdCorePrintAuthGuard } from '@core/services/auth/print-auth.guard';
import { JbdRolesEnum } from './shared/misc/enums/roles.enum';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: JbdUiLayoutPageComponent,
    children: [
      {
        path: '',
        component: JbdAccountPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'benutzerkonto/einloggen',
            pathMatch: 'full',
          },
          {
            path: 'benutzerkonto/einloggen',
            component: JbdAccountLoginComponent,
            data: {
              routeTitle: 'ACCOUNT.LOGIN.ROUTE_TITLE',
            },
          },
          {
            path: 'benutzerkonto/registrieren',
            component: JbdAccountRegisterComponent,
            data: {
              routeTitle: 'CORE.ACCOUNT.REGISTER.ROUTE_TITLE',
            },
          },
          {
            path: 'benutzerkonto/aktivieren',
            component: JbdAccountActivateComponent,
            data: {
              routeTitle: 'ACCOUNT.ACTIVATE.ROUTE_TITLE',
            },
          },
          {
            path: 'benutzerkonto/passwort-vergessen',
            component: JbdAccountPasswordForgottenComponent,
            data: {
              routeTitle: 'CORE.ACCOUNT.PASSWORD_FORGOTTEN.ROUTE_TITLE',
            },
          },
        ],
      },
      {
        path: 'onboarding',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.VIEW_ONLY],
        },
        loadChildren: () =>
          import('./onboarding/onboarding.module').then(
            (m) => m.JbdOnboardingModule
          ),
      },
      {
        path: 'aktivitaeten',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.VIEW_ONLY],
        },
        loadChildren: () =>
          import('./activity/activity.module').then((m) => m.JbdActivityModule),
      },
      {
        path: 'benutzerverwaltung',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.ADMIN],
        },
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.JbdUserManagementModule
          ),
      },
      {
        path: 'zahlungen',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.VIEW_ONLY],
        },
        loadChildren: () =>
          import('./payments/payments.module').then((m) => m.JbdPaymentsModule),
      },
      {
        path: 'benutzer',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.VIEW_ONLY],
        },
        loadChildren: () =>
          import('./user/user.module').then((m) => m.JbdUserModule),
      },
      {
        path: 'ihr-unternehmen',
        canMatch: [
          (route: Route, segments: UrlSegment[]) =>
            inject(JbdCoreAuthGuard).canMatch(route, segments),
        ],
        canActivateChild: [
          (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
        ],
        data: {
          roles: [JbdRolesEnum.CAMPAIGN_MANAGER],
        },
        loadChildren: () =>
          import('./company/company.module').then((m) => m.JbdCompanyModule),
      },
    ],
  },
  {
    path: 'kampagne-buchen',
    canMatch: [
      (route: Route, segments: UrlSegment[]) =>
        inject(JbdCoreAuthGuard).canMatch(route, segments),
    ],
    canActivateChild: [
      (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
    ],
    data: {
      roles: [JbdRolesEnum.CAMPAIGN_MANAGER],
    },
    loadChildren: () =>
      import('./order/order.module').then((m) => m.JbdOrderModule),
  },
  {
    path: 'kampagnen',
    canMatch: [
      (route: Route, segments: UrlSegment[]) =>
        sessionStorage.getItem('is-print-view')
          ? inject(JbdCorePrintAuthGuard).canMatch(route, segments)
          : inject(JbdCoreAuthGuard).canMatch(route, segments),
    ],
    canActivateChild: [
      (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        sessionStorage.getItem('is-print-view')
          ? inject(JbdCorePrintAuthGuard).canActivateChild(childRoute, state)
          : inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
    ],
    data: {
      roles: [JbdRolesEnum.VIEW_ONLY],
    },
    loadChildren: () =>
      import('./campaign/campaign.module').then((m) => m.JbdCampaignModule),
  },
  {
    path: 'auswertung',
    canMatch: [
      (route: Route, segments: UrlSegment[]) =>
        sessionStorage.getItem('is-print-view')
          ? inject(JbdCorePrintAuthGuard).canMatch(route, segments)
          : inject(JbdCoreAuthGuard).canMatch(route, segments),
    ],
    canActivateChild: [
      (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        sessionStorage.getItem('is-print-view')
          ? inject(JbdCorePrintAuthGuard).canActivateChild(childRoute, state)
          : inject(JbdCoreAuthGuard).canActivateChild(childRoute, state),
    ],
    data: {
      roles: [JbdRolesEnum.VIEW_ONLY],
    },
    loadChildren: () =>
      import('./report/report.module').then((m) => m.JbdReportModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'benutzerkonto/einloggen',
  },
];
