import { Injectable } from '@angular/core';
import { JbdCoreAuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class JbdCorePrintAuthGuard extends JbdCoreAuthGuard {
  protected checkLogin(): boolean {
    return true;
  }
}
