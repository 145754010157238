<ng-container *transloco="let t; read: 'SHARED.ORDER.CALCULATION'">
  <aside
    *ngIf="vat"
    class="font-size-sm"
  >
    <section
      fxLayoutGap="16px"
      fxLayoutAlign="space-between start"
    >
      <span>{{t('BOOKINGS')}}</span>

      <span
        class="font-weight-semibold">{{(order.totalOrderValue) / 100 | currency: 'EUR':'symbol':'1.2-2' }}</span>
    </section>
    <span
      class="block font-size-xxs text-monochrome-700"
      [innerHTML]="t('SUMMARY', {
        locations: calculationService.getLocationLength(order._embedded.locations),
        weeks: calculationService.getBookingWeeks(order.dates),
        attentionSpan: attentionService.calculateAttentionSpan(
          order.totalOrderValue,
          10000
        ) / 100 | number: '1.0-0'
      })"
    ></span>

    <section
      *ngIf="orderService.updateForm.value.headhunting!.headhuntingBudget || order.headhuntingBudget"
      @fade-toggle
      fxLayoutGap="16px"
      fxLayoutAlign="space-between start"
      class="mt-8"
    >
      <span>{{t('HEADHUNTING_PROVISION')}}&thinsp;*</span>

      <span
        class="font-weight-semibold">{{0 | currency: 'EUR':'symbol':'1.2-2' }}</span>
    </section>

    <hr class="border-color-200 my-12">

    <section
      fxLayoutGap="8px"
      fxLayout="column"
    >
      <div
        class="font-weight-bold"
        fxLayoutGap="16px"
        fxLayoutAlign="space-between start"
      >
        <span>{{t('LIST.NET')}}</span>
        <span
          [attr.data-e2e]="'campaign-details-calculation-net'"
        >{{(order.totalOrderValue) / 100 | currency: 'EUR':'symbol':'1.2-2' }}</span>
      </div>
      <div
        fxLayoutGap="16px"
        fxLayoutAlign="space-between start"
      >
        <span
        >{{t('LIST.VAT', { vat: vat / 100 | percent:'1.0-0':'en-US' }) }}</span>
        <span
          [attr.data-e2e]="'campaign-details-calculation-vat'"
          class="font-weight-semibold"
        >{{(order.totalOrderValue) / 100 * vat / 100 | currency: 'EUR':'symbol':'1.2-2' }}</span>
      </div>
    </section>

    <hr class="border-color-200 my-12">

    <section
      fxLayoutGap="16px"
      fxLayoutAlign="space-between center"
      class="font-weight-bold"
    >
      <span>{{t('LIST.GROSS')}}</span>
      <span
        [attr.data-e2e]="'campaign-details-calculation-gross'"
        class="font-size-base"
      >{{((order.totalOrderValue) / 100) + ((order.totalOrderValue) / 100 * vat / 100) | currency: 'EUR':'symbol':'1.2-2' }}</span>
    </section>
  </aside>
</ng-container>
