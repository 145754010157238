import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { JbdCoreOptionService } from '@core/services/option/option.service';
import { JbdCoreOptionKeyEnum } from '@core/shared/misc/enums/option-key.enum';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { JbdOrderService } from '../../../order/shared/services/order/order.service';
import { IJbdOrder } from '../../misc/interfaces/order.interface';
import { JbdAttentionService } from '../../services/attention/attention.service';
import { JbdCalculationService } from '../../services/calculation/calculation.service';

@Component({
  selector: 'jbd-app-shared-order-calculation',
  templateUrl: './order-calculation.component.html',
  styleUrls: ['./order-calculation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdSharedOrderCalculationComponent {
  public vat = +this.optionService.getOptionValueByKey(
    JbdCoreOptionKeyEnum.VAT
  );

  @Input() public order!: IJbdOrder;

  constructor(
    private optionService: JbdCoreOptionService,
    public attentionService: JbdAttentionService,
    public calculationService: JbdCalculationService,
    public cdRef: ChangeDetectorRef,
    public orderService: JbdOrderService
  ) {}
}
