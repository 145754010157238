import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JbdUiLayoutService } from '@ui/compositions/layout/shared/layout/layout.service';
import { Router } from '@angular/router';
import { CUSTOMER_MENU_ITEMS } from '../shared/misc/constants/menu-links.constants';
import { JbdActivityService } from '../../shared/services/activity/activity.service';
import { map } from 'rxjs/operators';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { JbdCustomerService } from '../../shared/services/customer/customer.service';
import { JbdCustomerEventService } from '../../shared/services/customer-event/customer-event.service';
import { startWith } from 'rxjs';
import { JbdCoreUserRoleService } from '@core/services/user-role/user-role.service';
import { JbdRolesEnum } from '../../shared/misc/enums/roles.enum';

@Component({
  selector: 'jbd-app-navigation',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdNavigationPageComponent {
  public readonly rolesEnum = JbdRolesEnum;
  public activities$ = this.activityService
    .onUpdate()
    .pipe(
      map((activities) =>
        (activities ?? []).filter((activity) => !activity.dismissed)
      )
    );

  public isCustomerVerified$ = this.customerEventService.onUpdate().pipe(
    startWith(this.customerService.isCustomerVerified()),
    map(() => this.customerService.isCustomerVerified())
  );

  public items = CUSTOMER_MENU_ITEMS;

  constructor(
    private activityService: JbdActivityService,
    private customerEventService: JbdCustomerEventService,
    private customerService: JbdCustomerService,
    public roleService: JbdCoreUserRoleService,
    public router: Router,
    public layoutService: JbdUiLayoutService
  ) {}
}
