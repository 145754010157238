import {
  faFacebookF,
  faLinkedin,
  faTwitter,
  faXing,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowsAlt,
  faCoins as fadCoins,
  faCreditCard,
  faInfoCircle,
  faMapMarkedAlt,
  faReceipt,
  faRocketLaunch,
  faUmbrellaBeach,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCopy,
  faEye as farEye,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBars,
  faBell,
  faBuilding,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCoins as fasCoins,
  faDownload,
  faEllipsisV,
  faEmptySet,
  faEquals,
  faExternalLink,
  faEye as fasEye,
  faEyeSlash,
  faList,
  faMap,
  faPaperPlane,
  faPen,
  faPlus,
  faPrint,
  faSearch,
  faShareAlt,
  faSlidersH,
  faTrashAlt,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';

export const ICONS = [
  faArrowsAlt,
  faBars,
  faBell,
  faBuilding,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCopy,
  faCreditCard,
  fadCoins,
  faDownload,
  faEllipsisV,
  faEmptySet,
  faEquals,
  faExternalLink,
  faEyeSlash,
  faFacebookF,
  faInfoCircle,
  faLinkedin,
  faList,
  faMap,
  faMapMarkedAlt,
  faPaperPlane,
  faPen,
  faPlus,
  faPrint,
  faQuestionCircle,
  faReceipt,
  farEye,
  faRocketLaunch,
  fasCoins,
  faSearch,
  fasEye,
  faShareAlt,
  faSlidersH,
  faTimes,
  faTrashAlt,
  faTwitter,
  faUmbrellaBeach,
  faUsers,
  faXing,
];
