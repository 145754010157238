<ng-container *transloco="let t">
  <header
    fxLayoutAlign="space-between baseline"
    fxLayoutGap="16px"
  >
    <h6 class="m-0">{{title}}</h6>

    <jbd-ui-button-tertiary
      [label]="t('SHARED.SECTION_TOGGLE.ACTION.PRIMARY')"
      [size]="'sm'"
      [iconPosition]="'post'"
      [icon]="'fas_chevron-down'"
      [classList]="['section-toggle-btn', (isExpanded ? 'is-expanded' : '')]"
      (clicked)="isExpanded = !isExpanded"
    ></jbd-ui-button-tertiary>
  </header>

  <main
    *ngIf="isExpanded"
    @fade-toggle
    class="mt-12"
  >
    <ng-content></ng-content>
  </main>
</ng-container>
