export const environment = {
  name: 'qs',
  production: true,
  gMapsApiKey: 'AIzaSyDbQAGxFA3b1IiiSiJzR26ghbcROl4MYIY',
  gMapsId: '1464c1c77955df77',
  sentryId:
    'https://154f1ff4550f44148793983af83f6309@o390785.ingest.sentry.io/6039039',
  sentryTracesSampleRate: 0.05,
  serverUrl: 'https://qs-backend-booking2.jobodo.de',
  shortLinkUrl: 'https://jobs.jobodo.de',
  appDetails: {
    type: 'customer',
    urls: {
      login: '/benutzerkonto/einloggen',
      onboarding: '/onboarding',
      dashboard: '/aktivitaeten',
      passwordReset: '/benutzer/sicherheit',
      partnerPlatform: 'https://qs-partner-booking2.jobodo.de',
    },
  },
  stripe: {
    publicKey:
      'pk_test_51JFEiEJrVUaYPnObJxN6VGKLNQ6CfNIorbdBaMBYqCKZXXaQp111fuWeC3MWVv8LdduerUwJWQoKXzNzXXio0uz800N1jorLYJ',
  },
  websockets: {
    source: 'https://qs-mercure-booking2.jobodo.de/.well-known/mercure',
    channels: [
      {
        topic: 'dates',
        url: 'location/:locationId/dates',
      },
      {
        topic: 'activity',
        url: 'activity/:userId',
      },
    ],
  },
};
