<ng-container *transloco="let t">
  <div
    *ngIf="roleService.hasRole(rolesEnum.CAMPAIGN_MANAGER)"
    class="w-100 px-16 mb-32"
  >
    <jbd-ui-button-primary
      [icon]="'fas_chevron-right'"
      [iconPosition]="'post'"
      [label]="t('NAVIGATION.ACTION.PRIMARY')"
      [autoStretch]="true"
      [disabled]="(isCustomerVerified$ | async) === false"
      (clicked)="router.navigate(['/kampagne-buchen'])"
    ></jbd-ui-button-primary>
  </div>

  <mat-nav-list
    [attr.data-e2e]="'customer-menu'"
    class="p-0 pr-16"
    fxLayout="column"
    fxLayoutGap="4px"
  >
    <ng-container *ngFor="let item of items">
      <a
        *ngIf="roleService.hasRole(item.neededRole)"
        mat-list-item
        routerLink="{{item.path}}"
        routerLinkActive="is-active"
        (click)="layoutService.closeSidenav()"
      >
        <mat-icon
          class="font-size-base mr-12"
          svgIcon="{{item.icon}}"
          matListItemIcon
        ></mat-icon>

        <span
          matListItemTitle
          class="text-monochrome-900 font-size-sm font-weight-semibold"
        >{{t(item.label)}}</span>

        <jbd-app-navigation-badge
          *ngIf="item.id === 'activities' && (activities$ | async) && (activities$ | async)!.length > 0"
          @fade-toggle
          [count]="(activities$ | async)!.length"
          matListItemMeta
        ></jbd-app-navigation-badge>
      </a>
    </ng-container>
  </mat-nav-list>
</ng-container>
