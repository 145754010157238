import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JbdCoreOptionKeyEnum } from '../../shared/misc/enums/option-key.enum';
import { IJbdCoreDataOptionResponse } from '../data/data.interface';
import { JbdCoreDataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class JbdCoreOptionService {
  constructor(private dataService: JbdCoreDataService) {}

  public getOptionValueByKey(key: JbdCoreOptionKeyEnum): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.getOptions().find((option) => option.optionKey === key)!
      .optionValue;
  }

  public getOptions(): IJbdCoreDataOptionResponse[] {
    const optionsString = localStorage.getItem('options') ?? '';

    if (optionsString) {
      return JSON.parse(decodeURIComponent(escape(atob(optionsString))));
    }

    return [];
  }

  public clearOptions(): void {
    localStorage.removeItem('options');
  }

  public setOptions(optionsString: IJbdCoreDataOptionResponse[]): void {
    localStorage.setItem(
      'options',
      btoa(unescape(encodeURIComponent(JSON.stringify(optionsString))))
    );
  }

  public loadOptions(): Observable<IJbdCoreDataOptionResponse[]> {
    return this.dataService
      .getOptions()
      .pipe(tap((options) => this.setOptions(options)));
  }
}
