import { Injectable } from '@angular/core';
import { differenceInCalendarWeeks, parseISO } from 'date-fns';
import { IJbdOrderLocation } from '../../misc/interfaces/order.interface';
import { IJbdDates } from '@core/shared/misc/interfaces/dates.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdCalculationService {
  public getLocationLength(locations: IJbdOrderLocation[]): number {
    return locations
      .map(({ bookings }: IJbdOrderLocation) => bookings)
      .reduce((bookingLength, bookings) => bookingLength + bookings.length, 0);
  }

  public getBookingWeeks(dates: IJbdDates): number {
    return differenceInCalendarWeeks(
      parseISO(dates.latestBookingEnd!),
      parseISO(dates.earliestBookingStart!)
    );
  }
}
