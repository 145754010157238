import { NgModule } from '@angular/core';
import { JbdCoreModule } from '@core';
import { NgxStripeModule } from 'ngx-stripe';
import { IconsModule } from '../icons/icons.module';
import { MaterialModule } from '../material/material.module';
import { JbdSharedAddPaymentDetailsDialogComponent } from './components/dialog/add-payment-details/add-payment-details.component';
import { JbdSharedOrderCalculationComponent } from './components/order-calculation/order-calculation.component.component';
import { JbdSharedSectionToggleComponent } from './components/section-toggle/section-toggle.component';
import { JbdSharedSearchComponent } from './components/search/search.component';
import { JbdUiButtonTertiaryComponent } from '@ui/elements/button/tertiary/tertiary.component';
import { JbdUiButtonPrimaryComponent } from '@ui/elements/button/primary/primary.component';
import { JbdUiAlertComponent } from '@ui/elements/alert/alert.component';
import { JbdUiFormFieldInputComponent } from '@ui/compositions/form/field/input/input.component';
import { JbdUiFormUnattachedErrorComponent } from '@ui/compositions/form/shared/components/unattached-error/unattached-error.component';
import { JbdUiFormFieldCheckboxComponent } from '@ui/compositions/form/field/checkbox/checkbox.component';

const IMPORTED_MODULES = [NgxStripeModule];
const IMPORTED_COMPONENTS = [
  JbdUiAlertComponent,
  JbdUiButtonTertiaryComponent,
  JbdUiFormFieldInputComponent,
  JbdUiFormUnattachedErrorComponent,
  JbdUiButtonPrimaryComponent,
  JbdUiFormFieldCheckboxComponent,
];

const SHARED_MODULES = [IconsModule, JbdCoreModule, MaterialModule];

const SHARED_COMPONENTS = [
  JbdSharedAddPaymentDetailsDialogComponent,
  JbdSharedOrderCalculationComponent,
  JbdSharedSectionToggleComponent,
  JbdSharedSearchComponent,
];

@NgModule({
  imports: [...IMPORTED_MODULES, ...SHARED_MODULES, ...IMPORTED_COMPONENTS],
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS],
})
export class JbdSharedModule {}
