export const CUSTOMER_API_PATHS = {
  CUSTOMER: {
    ME: 'customers/:customerId',
    ATTRIBUTES: 'customers/:customerId/attributes',
    CONTACT: 'customers/:customerId/contact',
  },
  ACTIVITY: {
    LIST: 'users/me/activities',
    DETAILS: 'users/me/activities/:activityId',
  },
  USER_MANAGEMENT: {
    LIST: 'customers/:customerId/users',
    DETAILS: 'customers/:customerId/users/:userId',
  },
  PAYMENTS: {
    INVOICES: 'customers/:customerId/invoices',
    INVOICE_DOWNLOAD: 'customers/:customerId/invoices/:invoiceId/download',
    PREPAID_TRANSACTIONS: 'customers/:customerId/prepaidTransactions',
    PREPAID_TRANSACTIONS_DOWNLOAD:
      'customers/:customerId/prepaidTransactions/download',
    PAYMENT_DETAILS: 'customers/:customerId/payments',
    PAYMENT_DETAILS_DETAIL: 'customers/:customerId/payments/:paymentDetailId',
    PAYMENT_TOKEN: 'customers/:customerId/payments/stripe/token',
  },
  ADDRESS: {
    LIST: 'customers/:customerId/addresses',
    DETAILS: 'customers/:customerId/addresses/:addressId',
  },
  ORDER: {
    SKILLS: 'skills',
    REGIONS: 'regions',
    LIST: 'customers/:customerId/orders',
    DETAILS: 'customers/:customerId/orders/:orderId',
    DETAILS_DOWNLOAD: 'customers/:customerId/orders/:orderId/pdf',
    UPDATE: 'customers/:customerId/orders/:orderId/updateWebsocket',
    ADD: 'customers/:customerId/orders/:orderId/locations/:locationId/updateWebsocket',
    LOCATIONS: 'customers/:customerId/orders/:orderId/locations',
    BOOKING_DETAILS:
      'customers/:customerId/orders/:orderId/bookings/:bookingId',
    FILES: 'customers/:customerId/orders/:orderId/files',
  },
};
