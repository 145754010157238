import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IJbdCoreDataLoginLink } from '@core/services/data/data.interface';
import { JbdCoreDataService } from '@core/services/data/data.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { TranslocoService } from '@jsverse/transloco';
import { ILegalLinks } from '@ui/compositions/modules/account/legal-links/legal-links.interface';
import { IJbdAlert } from '@ui/elements/alert/alert.interface';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { IJbdDocumentLink } from '@core/shared/misc/interfaces/document-links.interface';
import { SITE_NOTICE } from '@core/shared/misc/constants/global.constants';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Component({
  selector: 'jbd-app-account-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdAccountPasswordForgottenComponent implements OnInit {
  public alert: IJbdAlert = {
    isVisible: false,
    message: '',
    type: 'danger',
    isDismissible: true,
  };
  public legalLinks$: Observable<ILegalLinks> = this.documentsService
    .onUpdate()
    .pipe(
      map((documents: IJbdDocumentLink[]) => ({
        privacyAgreement: documents.find((d) => d.type === 'PRIVACY_AGREEMENT')!
          .url,
        gtc: documents.find((d) => d.type === 'GTC_CUSTOMER')!.url,
        siteNotice: SITE_NOTICE.url,
      })),
      startWith({
        privacyAgreement: '',
        gtc: '',
        siteNotice: SITE_NOTICE.url,
      } as ILegalLinks)
    );
  public passwordForgottenForm!: UntypedFormGroup;
  public successfulSubmitted = false;
  @ViewChild('alertElement', { static: false })
  public alertElement!: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private coreDataService: JbdCoreDataService,
    private documentsService: JbdCoreDocumentsService,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private userService: JbdCoreUserService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    this.redirectUserIfLoggedIn();
    this.createForm();
  }

  private redirectUserIfLoggedIn() {
    if (this.userService.isLoggedIn()) {
      void this.router.navigate([this.userService.getUserRoute()]);
    }
  }

  private createForm(): void {
    this.passwordForgottenForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      withPasswordReset: true,
    });
  }

  public onSubmit() {
    this.passwordForgottenForm.markAllAsTouched();

    if (this.passwordForgottenForm.invalid) {
      return;
    }

    this.passwordForgottenForm.disable();

    this.handleFormSubmit(this.passwordForgottenForm.value);
  }

  private handleFormSubmit(value: IJbdCoreDataLoginLink): void {
    this.coreDataService.createLoginLink(value).subscribe({
      next: () => this.handleSuccessResponse(),
      error: () => this.handleErrorResponse(),
    });
  }

  private handleSuccessResponse(): void {
    this.successfulSubmitted = true;
    this.cdRef.markForCheck();
  }

  private handleErrorResponse(): void {
    this.passwordForgottenForm.enable();
    this.alert.message = this.translocoService.translate(
      'UI.SHARED.FORM.ALERT.ERROR.FORMALLY.GENERIC'
    );
    this.alert.isVisible = true;
    this.alertElement.nativeElement.scrollIntoView(
      JBD_CORE_SCROLL_CONFIG_TO_END
    );
    this.cdRef.markForCheck();
  }
}
