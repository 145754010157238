import { Component, OnDestroy, OnInit } from '@angular/core';
import { JbdCoreImpersonateService } from '@core/services/impersonate/impersonate.service';
import { JbdCoreOptionService } from '@core/services/option/option.service';
import { JbdCoreSessionService } from '@core/services/session/session.service';
import { JbdCoreTitleService } from '@core/services/title/title.service';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { IImpersonateDetails } from '@core/shared/misc/interfaces/impersonate-details.interface';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VERSION } from '../environments/version';
import { JbdUiLayoutService } from '@ui/compositions/layout/shared/layout/layout.service';
import { JbdNavigationPageComponent } from './navigation/page/page.component';
import { JbdActivityService } from './shared/services/activity/activity.service';
import { JbdCustomerEventService } from './shared/services/customer-event/customer-event.service';
import { JbdCustomerService } from './shared/services/customer/customer.service';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';

@Component({
  selector: 'jbd-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeToggle],
})
export class JbdAppComponent implements OnDestroy, OnInit {
  private ngUnsubscribe = new Subject<void>();
  public impersonateDetails!: IImpersonateDetails;

  constructor(
    private documentsService: JbdCoreDocumentsService,
    private activityService: JbdActivityService,
    private customerEventService: JbdCustomerEventService,
    private customerService: JbdCustomerService,
    private layoutService: JbdUiLayoutService,
    private optionService: JbdCoreOptionService,
    private sessionService: JbdCoreSessionService,
    private titleService: JbdCoreTitleService,
    private userEventService: JbdCoreUserEventService
  ) {
    this.init();
  }

  private init(): void {
    this.renderImpersonationHint();

    if (sessionStorage.getItem('is-print-view')) {
      return;
    }

    this.documentsService.init();
    this.titleService.setRouteTitle('GENERAL.FALLBACK_ROUTE_TITLE');
    this.sessionService.initSessionCheck();
    this.activityService.enable();
    this.setSidenavList();
    this.handleLoginEvent();
    this.handleLogoutEvent();
  }

  public ngOnInit(): void {
    this.setAppVersion();
  }

  private setAppVersion(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).appVersion = VERSION;
  }

  private setSidenavList(): void {
    this.layoutService.sidenavListComponentRef = JbdNavigationPageComponent;
  }

  private renderImpersonationHint() {
    this.impersonateDetails =
      JbdCoreImpersonateService.getImpersonationDetails();
  }

  private handleLoginEvent(): void {
    this.userEventService
      .onLogin()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.customerEventService.triggerUpdate();
        this.activityService.enable();
        this.renderImpersonationHint();
      });
  }

  private handleLogoutEvent(): void {
    this.userEventService
      .onLogout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.activityService.disable();
        this.customerService.clearCustomer();
        this.optionService.clearOptions();
        this.sessionService.clearSessionCheck();
        this.renderImpersonationHint();
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.activityService.ngOnDestroy();
  }
}
