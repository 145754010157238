import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { transitionMessages } from '@core/utils/animations/transition-message.animation';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

/**
 * Note:
 *  This is an Error-Message,
 *  that can be used without an attached form-group
 */

@Component({
  selector: 'jbd-ui-form-unattached-error',
  templateUrl: './unattached-error.component.html',
  styleUrls: ['./unattached-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [transitionMessages],
  imports: [MatInputModule, FlexLayoutModule, MatIconModule, NgIf],
})
export class JbdUiFormUnattachedErrorComponent {
  public subscriptAnimationState = 'enter';

  @Input() public message!: string;
  @Input() public show = true;
}
