import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SCOPE } from '@core/services/data/data.endpoints';
import { IJbdCoreDataContactAdministrationRequestPayload } from '@core/services/data/data.interface';
import {
  appendFormData,
  replacePathSegmentWithId,
  setFormDataHeaders,
} from '@core/services/data/data.utils';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { IJbdEmbeddedAddress } from '@core/shared/misc/interfaces/adress.interface';
import { IJbdEmbeddedAttribute } from '@core/shared/misc/interfaces/embedded-attribute.interface';
import { IJbdFile } from '@core/shared/misc/interfaces/file.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { JbdChannelTypeEnum } from '../../misc/enums/channel-type.enum';
import { IJbdCustomer } from '../../misc/interfaces/customer.interface';
import { IJbdInvoice } from '../../misc/interfaces/invoice.interface';
import {
  IJbdOrder,
  IJbdOrderAddLocations,
} from '../../misc/interfaces/order.interface';
import { IJbdPaymentDetail } from '../../misc/interfaces/payment-detail.interface';
import { IJbdPrepaidTransaction } from '../../misc/interfaces/prepaid-transaction.interface';
import { IJbdRegion } from '../../misc/interfaces/region.interface';
import { IJbdSkill } from '../../misc/interfaces/skill.interface';
import { CUSTOMER_API_PATHS } from './data.endpoints';
import {
  IJbdDataAddSkillRequestPayload,
  IJbdDataOrderRemoveLocationTypeRequestPayload,
  IJbdDataOrderRequestPayload,
  IJbdDataOrderReserveRequestPayload,
  IJbdDataPaymentTokenResponse,
  IJbdDataUpdateCompanyRequestPayload,
  IJbdDataUserManagementUpsertUserRequestPayload,
  IJbdPathVariables,
} from './data.interface';
import { IJbdActivity } from '../../misc/interfaces/activity.interface';
import { IJbdUserListItem } from '@core/shared/misc/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdDataService {
  constructor(
    private http: HttpClient,
    private userService: JbdCoreUserService
  ) {}

  private getUrlPath(
    path: string,
    apiPathsObj: { [key: string]: string | { [key: string]: string } },
    {
      invoiceId,
      paymentDetailId,
      addressId,
      orderId,
      bookingId,
      dateId,
      locationId,
      activityId,
      userId,
    }: IJbdPathVariables
  ): string {
    let formattedPath: string = path
      .split('.')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce(
        (p: { [index: string]: never }, c) => p?.[c] || null,
        apiPathsObj
      );

    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'customerId',
      this.userService.customerId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'invoiceId',
      invoiceId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'paymentDetailId',
      paymentDetailId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'addressId',
      addressId
    );
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'activityId',
      activityId
    );
    formattedPath = replacePathSegmentWithId(formattedPath, 'orderId', orderId);
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'bookingId',
      bookingId
    );
    formattedPath = replacePathSegmentWithId(formattedPath, 'dateId', dateId);
    formattedPath = replacePathSegmentWithId(
      formattedPath,
      'locationId',
      locationId
    );
    formattedPath = replacePathSegmentWithId(formattedPath, 'userId', userId);

    return formattedPath;
  }

  private buildUrlFor(
    scope: string,
    path: string,
    pathVariables?: IJbdPathVariables
  ): string {
    const urlPath = this.getUrlPath(
      path,
      CUSTOMER_API_PATHS,
      pathVariables ?? {}
    );

    return `/${(API_SCOPE as { [key: string]: string })[scope]}/${urlPath}`;
  }

  // customer profile

  public getCustomer(): Observable<IJbdCustomer> {
    return this.http.get<IJbdCustomer>(this.buildUrlFor('DATA', 'CUSTOMER.ME'));
  }

  public updateCustomer(
    params: IJbdDataUpdateCompanyRequestPayload
  ): Observable<IJbdCustomer> {
    return this.http.patch<IJbdCustomer>(
      this.buildUrlFor('DATA', 'CUSTOMER.ME'),
      params
    );
  }

  public addCustomerAttribute(
    params: IJbdEmbeddedAttribute
  ): Observable<IJbdCustomer> {
    return this.http.post<IJbdCustomer>(
      this.buildUrlFor('DATA', 'CUSTOMER.ATTRIBUTES'),
      params
    );
  }

  // contact

  public contactAdministration(
    params: IJbdCoreDataContactAdministrationRequestPayload
  ): Observable<[]> {
    return this.http.post<[]>(
      this.buildUrlFor('DATA', 'CUSTOMER.CONTACT'),
      params
    );
  }

  // user-management

  public getUserList(): Observable<IJbdUserListItem[]> {
    return this.http.get<IJbdUserListItem[]>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.LIST')
    );
  }

  public addUser(
    requestPayload: IJbdDataUserManagementUpsertUserRequestPayload
  ): Observable<IJbdUserListItem> {
    return this.http.post<IJbdUserListItem>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.LIST'),
      requestPayload
    );
  }

  public updateUser(
    requestPayload: IJbdDataUserManagementUpsertUserRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdUserListItem> {
    return this.http.patch<IJbdUserListItem>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.DETAILS', pathVariables),
      requestPayload
    );
  }

  public deleteUser(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdUserListItem[]> {
    return this.http.delete<IJbdUserListItem[]>(
      this.buildUrlFor('DATA', 'USER_MANAGEMENT.DETAILS', pathVariables)
    );
  }

  // payments

  public getInvoices(): Observable<IJbdInvoice[]> {
    return this.http.get<IJbdInvoice[]>(
      this.buildUrlFor('DATA', 'PAYMENTS.INVOICES')
    );
  }

  public getInvoiceDownload(pathVariables: IJbdPathVariables): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'PAYMENTS.INVOICE_DOWNLOAD',
      pathVariables
    )}`;
  }

  public getPrepaidHistoryDownload(): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'PAYMENTS.PREPAID_TRANSACTIONS_DOWNLOAD'
    )}`;
  }

  public getPrepaidTransactions(): Observable<IJbdPrepaidTransaction[]> {
    return this.http.get<IJbdPrepaidTransaction[]>(
      this.buildUrlFor('DATA', 'PAYMENTS.PREPAID_TRANSACTIONS')
    );
  }

  public getPaymentDetails(): Observable<IJbdPaymentDetail[]> {
    return this.http.get<IJbdPaymentDetail[]>(
      this.buildUrlFor('DATA', 'PAYMENTS.PAYMENT_DETAILS')
    );
  }

  public updatePaymentDetail(
    params: { isDefault: boolean },
    pathVariables: IJbdPathVariables
  ): Observable<IJbdPaymentDetail[]> {
    return this.http.patch<IJbdPaymentDetail[]>(
      this.buildUrlFor(
        'DATA',
        'PAYMENTS.PAYMENT_DETAILS_DETAIL',
        pathVariables
      ),
      params
    );
  }

  public deletePaymentDetail(pathVariables: IJbdPathVariables): Observable<[]> {
    return this.http.delete<[]>(
      this.buildUrlFor('DATA', 'PAYMENTS.PAYMENT_DETAILS_DETAIL', pathVariables)
    );
  }

  public createPaymentToken(): Observable<IJbdDataPaymentTokenResponse> {
    return this.http.get<IJbdDataPaymentTokenResponse>(
      this.buildUrlFor('DATA', 'PAYMENTS.PAYMENT_TOKEN')
    );
  }

  // addresses

  public addAddress(
    params: IJbdEmbeddedAddress
  ): Observable<IJbdEmbeddedAddress[]> {
    return this.http.post<IJbdEmbeddedAddress[]>(
      this.buildUrlFor('DATA', 'ADDRESS.LIST'),
      params
    );
  }

  public updateAddress(
    params: IJbdEmbeddedAddress,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdEmbeddedAddress[]> {
    return this.http.patch<IJbdEmbeddedAddress[]>(
      this.buildUrlFor('DATA', 'ADDRESS.DETAILS', pathVariables),
      params
    );
  }

  public deleteAddress(pathVariables: IJbdPathVariables): Observable<[]> {
    return this.http.delete<[]>(
      this.buildUrlFor('DATA', 'ADDRESS.DETAILS', pathVariables)
    );
  }

  // skills

  public getSkills(): Observable<IJbdSkill[]> {
    return this.http.get<IJbdSkill[]>(this.buildUrlFor('DATA', 'ORDER.SKILLS'));
  }

  public addSkill(
    params: IJbdDataAddSkillRequestPayload
  ): Observable<IJbdSkill> {
    return this.http.post<IJbdSkill>(
      this.buildUrlFor('DATA', 'ORDER.SKILLS'),
      params
    );
  }

  // regions

  public getRegions(): Observable<IJbdRegion[]> {
    return this.http.get<IJbdRegion[]>(
      this.buildUrlFor('DATA', 'ORDER.REGIONS')
    );
  }

  // order

  public createOrder(
    params: IJbdDataOrderRequestPayload
  ): Observable<IJbdOrder> {
    return this.http.post<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.LIST'),
      params
    );
  }

  public deleteOrder(pathVariables: IJbdPathVariables): Observable<[]> {
    return this.http.delete<[]>(
      this.buildUrlFor('DATA', 'ORDER.DETAILS', pathVariables)
    );
  }

  public updateOrder(
    params: IJbdDataOrderRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrder> {
    return this.http.patch<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.DETAILS', pathVariables),
      params
    );
  }

  public getOrder(pathVariables: IJbdPathVariables): Observable<IJbdOrder> {
    return this.http.get<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.DETAILS', pathVariables)
    );
  }

  public getOrders(): Observable<IJbdOrder[]> {
    return this.http.get<IJbdOrder[]>(this.buildUrlFor('DATA', 'ORDER.LIST'));
  }

  public getOrderPdf(pathVariables: IJbdPathVariables): string {
    return `${environment.serverUrl}${this.buildUrlFor(
      'DATA',
      'ORDER.DETAILS_DOWNLOAD',
      pathVariables
    )}`;
  }

  public getOrderLocations(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrderAddLocations[]> {
    return this.http.get<IJbdOrderAddLocations[]>(
      this.buildUrlFor('DATA', 'ORDER.LOCATIONS', pathVariables)
    );
  }

  public reserveOrderLocationUnit(
    params: IJbdDataOrderReserveRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrder> {
    return this.http.post<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.DETAILS', pathVariables),
      params
    );
  }

  public reserveOrderSpecificLocationUnit(
    params: IJbdDataOrderReserveRequestPayload,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrder> {
    return this.http.post<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.BOOKING_DETAILS', pathVariables),
      params
    );
  }

  public removeOrderLocation(
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrder> {
    return this.http.delete<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.BOOKING_DETAILS', pathVariables)
    );
  }

  public removeLocationType(
    params: IJbdDataOrderRemoveLocationTypeRequestPayload
  ): Observable<IJbdOrder> {
    return this.http.post<IJbdOrder>(
      this.buildUrlFor('DATA', 'ORDER.LIST'),
      params
    );
  }

  public triggerChannelUpdate(
    pathVariables: IJbdPathVariables,
    channelType: JbdChannelTypeEnum
  ): Observable<IJbdOrder> {
    return this.http.get<IJbdOrder>(
      this.buildUrlFor('DATA', `ORDER.${channelType}`, pathVariables)
    );
  }

  // campaign

  public addCampaignFlyerFile(
    requestPayload: IJbdFile,
    pathVariables: IJbdPathVariables
  ): Observable<IJbdOrder> {
    const formData = appendFormData(requestPayload, new FormData());
    const headers = setFormDataHeaders();
    const url = this.buildUrlFor('DATA', 'ORDER.FILES', pathVariables);

    return this.http.post<IJbdOrder>(url, formData, { headers });
  }

  // activity
  public getActivities(): Observable<IJbdActivity[]> {
    return this.http.get<IJbdActivity[]>(
      this.buildUrlFor('DATA', 'ACTIVITY.LIST')
    );
  }

  public markAllActivitiesAsRead(params: {
    transition: 'read';
  }): Observable<IJbdActivity[]> {
    return this.http.patch<IJbdActivity[]>(
      this.buildUrlFor('DATA', 'ACTIVITY.LIST'),
      params
    );
  }

  public markActivityAsRead(
    pathVariables: IJbdPathVariables,
    params: { transition: 'read' }
  ): Observable<IJbdActivity[]> {
    return this.http.patch<IJbdActivity[]>(
      this.buildUrlFor('DATA', 'ACTIVITY.DETAILS', pathVariables),
      params
    );
  }
}
