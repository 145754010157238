import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jbd-app-shared-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdSharedSearchComponent {
  @Input() public formGroup!: FormGroup;
  @Input() public name!: string;
  @Input() public placeholder = '';

  public resetSearch(): void {
    this.formGroup.get(this.name)!.reset('');

    setTimeout(() => {
      document.getElementById(this.name)!.blur();
    });
  }
}
