import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JbdCorePreferredContactWayEnum } from '@core/shared/misc/enums/preferred-contact-way.enum';
import { JbdCoreSalutationEnum } from '@core/shared/misc/enums/salutation.enum';
import {
  catchError,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
  timeout,
} from 'rxjs';
import { IJbdOrder } from '../../../../shared/misc/interfaces/order.interface';
import { JbdPaymentTypeEnum } from '../../../../shared/misc/interfaces/payment-type.enum';
import { JbdAddressService } from '../../../../shared/services/address/address.service';
import { JbdDataService } from '../../../../shared/services/data/data.service';
import { MIN_HEADHUNTING_BUDGET } from '../../misc/models/headhunting.model';

@Injectable({
  providedIn: 'root',
})
export class JbdOrderService {
  private _orderId = '';
  private order = new ReplaySubject<IJbdOrder | null>(1);
  private order$ = this.order.asObservable();
  private readonly preferredContactWayEnum = JbdCorePreferredContactWayEnum;
  public minHeadhuntingBudget = MIN_HEADHUNTING_BUDGET / 100;
  public readonly salutationEnum = JbdCoreSalutationEnum;

  public form = this.fb.group({
    mainGroupSkill: ['', Validators.required],
    subGroupSkill: '',
    region: ['', Validators.required],
    skills: null,
    start: null,
    end: null,
    hasEndDate: false,
    budget: null,
  });

  public updateForm = this.fb.nonNullable.group({
    headhunting: this.fb.nonNullable.group({
      headhuntingBudget: [
        <null | number>null,
        [Validators.required, Validators.min(this.minHeadhuntingBudget)],
      ],
      headhuntingPerson: this.fb.nonNullable.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        phone: '',
        email: '',
        preferredContactWay: this.preferredContactWayEnum.EMAIL,
        salutation: this.salutationEnum.FEMALE,
      }),
      isDefault: false,
    }),
    paymentDetails: this.fb.nonNullable.group({
      addPaymentDetails: this.fb.nonNullable.group({
        paymentType: JbdPaymentTypeEnum.INVOICE,
        paymentDetail: null,
      }),
      invoiceAddress: this.fb.nonNullable.group({
        address: this.addressService.createForm(),
        addressId: null,
        saveAddress: false,
        ustId: '',
        costcenter: '',
      }),
    }),
  });

  get orderId(): string {
    return this._orderId;
  }

  set orderId(value: string) {
    this._orderId = value;
  }

  constructor(
    private addressService: JbdAddressService,
    private dataService: JbdDataService,
    private fb: UntypedFormBuilder
  ) {}

  public setOrder(order: IJbdOrder | null): void {
    this.orderId = order ? order.id : '';
    this.order.next(order);
  }

  public onOrderUpdate(): Observable<IJbdOrder | null> {
    return this.order$;
  }

  public async getOrder(): Promise<IJbdOrder | null> {
    return firstValueFrom(
      this.onOrderUpdate().pipe(
        timeout(3000),
        catchError(() => of(null))
      )
    );
  }

  public clearOrder(): void {
    this.setOrder(null);
  }

  public async deleteOrder(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.orderId) {
        resolve();
        return;
      }

      this.dataService.deleteOrder({ orderId: this.orderId }).subscribe(() => {
        this.setOrder(null);
        resolve();
      });
    });
  }
}
