<ng-container *transloco="let t; read: 'SHARED.PAYMENTS.PAYMENT_DETAILS.DIALOG.ADD.FORM'">
  <main mat-dialog-content>
    <form
      [formGroup]="addCardForm"
      (ngSubmit)="onSubmit()"
      [ngClass]="{ 'is-disabled': isLoading }"
      [elementsOptions]="elementsOptions"
      ngxStripeCardGroup
      id="addCardForm"
      fxLayout="column"
      fxLayoutAlign="start"
      novalidate
    >
      <div #alertElement>
        <jbd-ui-alert
          *ngIf="alert.isVisible"
          @fade-toggle
          [type]="alert.type"
          [message]="alert.message"
          [isDismissible]="alert.isDismissible"
          (dismissed)="alert.isVisible = false"
          class="block mb-16"
        ></jbd-ui-alert>
      </div>

      <div
        class="form-group stripe-input card-number-input mb-32"
        [ngClass]="{'is-disabled': cardNumberField.disabled, 'is-invalid': cardNumberField.invalid}"
      >
        <label class="mat-label block">{{t('FIELD.CARD_NUMBER.LABEL')}}</label>
        <ngx-stripe-card-number
          [options]="cardOptions"
          (change)="handleInputChange($event, cardNumberField)"
        ></ngx-stripe-card-number>
        <jbd-ui-form-unattached-error
          [show]="cardNumberField.invalid"
          [message]="cardNumberField.message"
        ></jbd-ui-form-unattached-error>
      </div>

      <div fxLayoutAlign="start start"
           fxLayoutGap="12px"
           class="mb-32">
        <div
          class="form-group stripe-input expiry-date-input"
          [ngClass]="{'is-disabled': expiryField.disabled, 'is-invalid': expiryField.invalid}"
        >
          <label class="mat-label block">{{t('FIELD.EXPIRY_DATE.LABEL')}}</label>
          <ngx-stripe-card-expiry
            [options]="cardOptions"
            (change)="handleInputChange($event, expiryField)"
          ></ngx-stripe-card-expiry>
          <jbd-ui-form-unattached-error
            [show]="expiryField.invalid"
            [message]="expiryField.message"
          ></jbd-ui-form-unattached-error>
        </div>

        <div
          class="form-group stripe-input cvc-input"
          [ngClass]="{'is-disabled': cvcField.disabled, 'is-invalid': cvcField.invalid}"
        >
          <label class="mat-label block">{{t('FIELD.CVV.LABEL')}}</label>
          <ngx-stripe-card-cvc
            [options]="cardOptions"
            (change)="handleInputChange($event, cvcField)"
          ></ngx-stripe-card-cvc>
          <jbd-ui-form-unattached-error
            [show]="cvcField.invalid"
            [message]="cvcField.message"
          ></jbd-ui-form-unattached-error>
        </div>
      </div>

      <div class="inline-block">
        <jbd-ui-form-checkbox
          [formGroup]="addCardForm"
          [name]="'isDefault'"
          [inlineLabel]="t('FIELD.IS_DEFAULT.INLINE_LABEL')"
        ></jbd-ui-form-checkbox>
      </div>
    </form>
  </main>
  <footer mat-dialog-actions>
    <jbd-ui-button-primary
      [label]="t('ACTION.PRIMARY')"
      [isLoading]="isLoading"
      [disabled]="isLoading"
      [e2eId]="'addCardForm'"
      [autoStretchOnMobile]="true"
      [form]="'addCardForm'"
      (clicked)="onSubmit()"
      class="ml-sm-auto"
    ></jbd-ui-button-primary>
  </footer>
</ng-container>
