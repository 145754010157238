<form [formGroup]="formGroup">
  <jbd-ui-form-input
    [prefixIcon]="'fas_search'"
    [type]="'search'"
    [placeholder]="placeholder"
    [formGroup]="formGroup"
    [name]="name"
    [suffixButton]="formGroup.get(name)!.value.toString().length > 0 ? {
        icon: 'far_times',
        class: 'clear-search-btn',
        isLoading: false
      } : undefined"
    (clickedSuffixButton)="resetSearch()"
  ></jbd-ui-form-input>
</form>
