import { IJbdCustomerMenuItem } from '../interfaces/menu-item.interface';
import { JbdRolesEnum } from '../../../../shared/misc/enums/roles.enum';

export const CUSTOMER_MENU_ITEMS: IJbdCustomerMenuItem[] = [
  {
    id: 'activities',
    icon: 'fas_bell',
    label: 'ACTIVITY.TITLE',
    path: '/aktivitaeten',
    neededRole: JbdRolesEnum.VIEW_ONLY,
  },
  {
    id: 'campaigns',
    icon: 'fas_map',
    label: 'CAMPAIGN.OVERVIEW.TITLE',
    path: '/kampagnen',
    neededRole: JbdRolesEnum.VIEW_ONLY,
  },
  {
    id: 'user-management',
    icon: 'fas_users',
    label: 'USER_MANAGEMENT.HEADER.TITLE',
    path: '/benutzerverwaltung',
    neededRole: JbdRolesEnum.ADMIN,
  },
  {
    id: 'payments',
    icon: 'fas_coins',
    label: 'PAYMENTS.TITLE',
    path: '/zahlungen',
    neededRole: JbdRolesEnum.VIEW_ONLY,
  },
  {
    id: 'company',
    icon: 'fas_building',
    label: 'COMPANY.TITLE',
    path: '/ihr-unternehmen',
    neededRole: JbdRolesEnum.CAMPAIGN_MANAGER,
  },
];
