import { Injectable, OnDestroy } from '@angular/core';
import { IJbdActivity } from '../../misc/interfaces/activity.interface';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  Observable,
  Subject,
} from 'rxjs';
import { JbdDataService } from '../data/data.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IJbdChannel } from '../../misc/interfaces/channel.interface';
import { environment } from '../../../../environments/environment';
import { replacePathSegmentWithId } from '@core/services/data/data.utils';
import { JbdWebsocketService } from '../websocket/websocket.service';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JbdActivityService implements OnDestroy {
  private activities = new BehaviorSubject<IJbdActivity[] | undefined>(
    undefined
  );
  private activities$ = this.activities.asObservable();
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private userService: JbdCoreUserService,
    private dataService: JbdDataService,
    private socketService: JbdWebsocketService
  ) {}

  private update(activities: IJbdActivity[] | undefined): void {
    this.activities.next(activities);
  }

  public onUpdate(): Observable<IJbdActivity[] | undefined> {
    return this.activities$;
  }

  public enable(): void {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.dataService
      .getActivities()
      .pipe(tap(() => this.initChannel()))
      .subscribe({
        next: this.handleSuccessResponse.bind(this),
        error: this.handleErrorResponse.bind(this),
      });
  }

  public markAllActivitiesAsRead(): void {
    this.dataService.markAllActivitiesAsRead({ transition: 'read' }).subscribe({
      next: this.handleSuccessResponse.bind(this),
      error: this.handleErrorResponse.bind(this),
    });
  }

  public markActivityAsRead(activityId: string): void {
    this.dataService
      .markActivityAsRead({ activityId }, { transition: 'read' })
      .subscribe({
        next: this.handleSuccessResponse.bind(this),
        error: this.handleErrorResponse.bind(this),
      });
  }

  private handleSuccessResponse(activities: IJbdActivity[] | undefined): void {
    this.update(activities);
  }

  public handleErrorResponse(error: HttpErrorResponse): void {
    this.activities.error(error);
  }

  private initChannel(): void {
    const channel = this.findConfigByTopic('activity');
    const path = this.createTopicPath(channel);
    this.socketService.createChannel(path);
    this.subscribeToChannelUpdates();
  }

  private findConfigByTopic(topicId: string): IJbdChannel {
    return environment.websockets.channels.find(
      (channel) => channel.topic === topicId
    )!;
  }

  private createTopicPath({ url: path }: IJbdChannel): string {
    return replacePathSegmentWithId(path, 'userId', this.userService.userId);
  }

  private subscribeToChannelUpdates(): void {
    this.socketService
      .onEventUpdate()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(({ type }: Event | MessageEvent) => type === 'message')
      )
      .subscribe({
        next: this.handleChannelUpdate.bind(this),
      });
  }

  private async handleChannelUpdate(
    update: Event | MessageEvent
  ): Promise<void> {
    const activities: IJbdActivity[] =
      (await firstValueFrom(this.onUpdate())) ?? [];
    const parsedUpdate: IJbdActivity = JSON.parse(
      (update as MessageEvent).data
    );
    activities.push(parsedUpdate);

    this.handleSuccessResponse(activities);
  }

  public disable(): void {
    this.update(undefined);
    this.ngUnsubscribe.next();
  }

  public ngOnDestroy(): void {
    this.activities.complete();
    this.ngUnsubscribe.complete();
    this.socketService.closeEventSource();
  }
}
