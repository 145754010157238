import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return forkJoin([
      this.http.get<Translation>(`/assets/translations/customer.${lang}.json`),
      this.http.get<Translation>(`/assets/ui/translations/ui.${lang}.json`),
      this.http.get<Translation>(`/assets/core/translations/core.${lang}.json`),
    ]).pipe(
      map((resp: Translation[]) => ({
        ...resp[0],
        ...resp[1],
        ...resp[2],
      }))
    );
  }
}
