<mat-error *ngIf="show">
  <div [@transition-messages]="subscriptAnimationState">
    <div
      fxLayout="row"
      fxLayoutAlign="start"
      fxLayoutGap="4px"
      class="mt-8"
      role="alert">
      <mat-icon
        svgIcon="far_exclamation-circle"
        class="font-size-sm"
      ></mat-icon>
      <span>{{ message }}</span>
    </div>
  </div>
</mat-error>
