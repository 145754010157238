import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JbdCoreDataService } from '@core/services/data/data.service';
import { JbdCoreImpersonateService } from '@core/services/impersonate/impersonate.service';
import { JbdCoreOptionService } from '@core/services/option/option.service';
import { JbdCorePasswordResetService } from '@core/services/password-reset/password-reset.service';
import { JbdCoreRedirectService } from '@core/services/redirect/redirect.service';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { IJbdUser } from '@core/shared/misc/interfaces/user.interface';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { TranslocoService } from '@jsverse/transloco';
import { ILegalLinks } from '@ui/compositions/modules/account/legal-links/legal-links.interface';
import { IJbdAlert } from '@ui/elements/alert/alert.interface';
import { JbdUiFormInputType } from '@ui/shared/misc/types/input';
import { map, switchMap, tap } from 'rxjs/operators';
import { DEFAULT_ANIMATION_DURATION } from '@core/utils/animations/animation.config';
import { environment } from '../../../environments/environment';
import { Observable, startWith } from 'rxjs';
import { IJbdDocumentLink } from '@core/shared/misc/interfaces/document-links.interface';
import { SITE_NOTICE } from '@core/shared/misc/constants/global.constants';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Component({
  selector: 'jbd-app-account-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeToggle],
})
export class JbdAccountLoginComponent implements OnInit {
  private readonly routeQueryParams!: Params;

  public alert: IJbdAlert = {
    isVisible: false,
    message: '',
    type: 'danger',
    isDismissible: true,
  };
  public passwordFieldType: JbdUiFormInputType = 'password';
  public legalLinks$: Observable<ILegalLinks> = this.documentsService
    .onUpdate()
    .pipe(
      map((documents: IJbdDocumentLink[]) => ({
        privacyAgreement: documents.find((d) => d.type === 'PRIVACY_AGREEMENT')!
          .url,
        gtc: documents.find((d) => d.type === 'GTC_CUSTOMER')!.url,
        siteNotice: SITE_NOTICE.url,
      })),
      startWith({
        privacyAgreement: '',
        gtc: '',
        siteNotice: SITE_NOTICE.url,
      } as ILegalLinks)
    );
  public loginForm!: UntypedFormGroup;

  @ViewChild('alertElement', { static: false })
  public alertElement!: ElementRef;

  constructor(
    private coreDataService: JbdCoreDataService,
    private documentsService: JbdCoreDocumentsService,
    private fb: UntypedFormBuilder,
    private impersonateService: JbdCoreImpersonateService,
    private passwordResetService: JbdCorePasswordResetService,
    private redirectService: JbdCoreRedirectService,
    private optionService: JbdCoreOptionService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private userEventService: JbdCoreUserEventService,
    private userService: JbdCoreUserService
  ) {
    this.routeQueryParams = this.route.snapshot.queryParams;
  }

  public ngOnInit(): void {
    this.redirectUserIfLoggedIn();
    this.createForm();
    this.handleImpersonation();
    this.handleOneTimeLink();
  }

  private redirectUserIfLoggedIn() {
    if (
      this.userService.isLoggedIn() &&
      !('_switch_user' in this.routeQueryParams)
    ) {
      void this.router.navigate([this.userService.getUserRoute()]);
    }
  }

  private createForm(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  public onSubmit() {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.invalid) {
      return;
    }

    this.handleSubmitFor('form');
  }

  private handleOneTimeLink() {
    if (
      !(
        window.location.search &&
        'hash' in this.routeQueryParams &&
        'user' in this.routeQueryParams &&
        'expires' in this.routeQueryParams
      )
    ) {
      return;
    }

    this.handleSubmitFor('link');
  }

  private handleImpersonation() {
    if (!('_switch_user' in this.routeQueryParams)) {
      return;
    }

    this.loginForm.disable();

    this.impersonateService
      .handleImpersonation()
      .pipe(switchMap(() => this.optionService.loadOptions()))
      .subscribe({
        next: () => this.handleSuccessResponse(),
        error: (error: HttpErrorResponse) =>
          this.handleErrorResponse(
            error,
            'ACCOUNT.LOGIN.FORM.ALERT.ERROR.GENERIC'
          ),
      });
  }

  private getEndpoint(via: 'form' | 'link'): Observable<void> {
    if (via === 'form') {
      return this.coreDataService.login(this.loginForm.value);
    } else {
      return this.coreDataService.loginViaLink(window.location.search);
    }
  }

  private handleSubmitFor(wayToLogin: 'form' | 'link') {
    this.loginForm.disable();
    this.getEndpoint(wayToLogin)
      .pipe(
        switchMap(() => this.userService.loadUser()),
        tap((user: IJbdUser) => {
          if (user.isPartner) {
            return;
          }

          this.userEventService.triggerLogin(user);
        }),
        switchMap(() => this.optionService.loadOptions())
      )
      .subscribe({
        next: this.handleSuccessResponse.bind(this),
        error: (error: HttpErrorResponse) =>
          this.handleErrorResponse(
            error,
            `ACCOUNT.LOGIN.FORM.ALERT.ERROR.${wayToLogin.toUpperCase()}`
          ),
      });
  }

  private handleSuccessResponse(): void {
    this.alert.isVisible = false;
    this.loginForm.reset();
    this.loginForm.enable();

    if (this.userService.getUser()?.isPartner) {
      this.handleLoginOnWrongPlatform();

      return;
    }

    this.handleRouting();
  }

  private handleRouting(): void {
    let route = this.userService.getUserRoute();

    if ('passwordResetToken' in this.routeQueryParams) {
      this.passwordResetService.preparePasswordResetRedirect(
        this.routeQueryParams.passwordResetToken
      );
      route = this.redirectService.getRedirectUrl();
    } else {
      JbdCoreRedirectService.clearRedirectUrl();
    }

    void this.router.navigate([route]);
  }

  private handleErrorResponse(
    error: HttpErrorResponse,
    messageToShow: string
  ): void {
    this.loginForm.enable();

    this.updateAlertElement({
      message: this.translocoService.translate(
        error.status === 401 || error.status === 498
          ? messageToShow
          : 'ACCOUNT.LOGIN.FORM.ALERT.ERROR.GENERIC'
      ),
      isVisible: true,
      type: 'danger',
    });
  }

  public updateAlertElement(alert: IJbdAlert): void {
    this.alert = alert;

    setTimeout(() => {
      this.alertElement.nativeElement.scrollIntoView(
        JBD_CORE_SCROLL_CONFIG_TO_END
      );
    }, DEFAULT_ANIMATION_DURATION);
  }

  private handleLoginOnWrongPlatform(): void {
    this.updateAlertElement({
      message: this.translocoService.translate(
        'ACCOUNT.LOGIN.FORM.ALERT.INFO.WRONG_PLATFORM',
        {
          url: environment.appDetails.urls.partnerPlatform,
        }
      ),
      isVisible: true,
      type: 'info',
    });

    void this.userService.logoutUser();
  }
}
