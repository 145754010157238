import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import { JbdCustomerService } from '../customer/customer.service';
import { JbdCustomerEventEnum } from '../../misc/enums/customer-event.enum';
import { IJbdCustomerEvent } from '../../misc/interfaces/customer-event.interface';
import { IJbdCustomer } from '../../misc/interfaces/customer.interface';

@Injectable({
  providedIn: 'root',
})
export class JbdCustomerEventService {
  private notify = new Subject<IJbdCustomerEvent>();

  constructor(private customerService: JbdCustomerService) {}

  public triggerUpdate() {
    this.customerService.loadCustomer().subscribe((data: IJbdCustomer) => {
      this.notify.next({
        type: JbdCustomerEventEnum.UPDATED,
        data,
      });
    });
  }

  public onEvent(): Observable<IJbdCustomerEvent> {
    return this.notify.asObservable();
  }

  public onUpdate(): Observable<IJbdCustomerEvent> {
    return this.onEvent().pipe(
      filter((e: IJbdCustomerEvent) => e.type === JbdCustomerEventEnum.UPDATED)
    );
  }
}
