import { Injectable } from '@angular/core';
import { JbdCoreOptionService } from '@core/services/option/option.service';
import { JbdCoreOptionKeyEnum } from '@core/shared/misc/enums/option-key.enum';

@Injectable({
  providedIn: 'root',
})
export class JbdAttentionService {
  get attentionFactor(): number {
    return this._attentionFactor;
  }
  get lengthOfStay(): number {
    return this._lengthOfStay;
  }
  private _attentionFactor = this.getOptionValue(
    JbdCoreOptionKeyEnum.ATTENTION
  );
  private _lengthOfStay = this.getOptionValue(
    JbdCoreOptionKeyEnum.ORDER_LENGTH_OF_STAY
  );

  constructor(private optionService: JbdCoreOptionService) {}

  public getOptionValue(optionKey: JbdCoreOptionKeyEnum) {
    const optionValue = this.optionService.getOptionValueByKey(optionKey);
    return parseFloat(optionValue);
  }

  public calculateAttentionSpan(
    balance: number,
    roundingDimension = 100
  ): number {
    return this.roundUp(
      (balance * 100) / this.attentionFactor,
      roundingDimension
    );
  }

  // TODO: move to own service if more calculation
  private roundUp(value: number, dimension = 100): number {
    return Math.ceil(value / dimension) * dimension;
  }
}
