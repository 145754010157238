import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeToggle } from '@core/utils/animations/fade-toggle.animation';
import { TranslocoService } from '@jsverse/transloco';
import {
  SetupIntent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { IJbdAlert } from '@ui/elements/alert/alert.interface';
import { JbdUiDialogContainerComponent } from '@ui/elements/dialog/container/container.component';
import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardNumberComponent,
  StripeService,
} from 'ngx-stripe';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  DEFAULT_STRIPE_CARD_ELEMENT_OPTIONS,
  DEFAULT_STRIPE_ELEMENTS_OPTIONS,
} from '../../../config/stripe.config';
import {
  IJbdPaymentDetail,
  IJbdPaymentDetailAddCardField,
} from '../../../misc/interfaces/payment-detail.interface';
import { IJbdDataPaymentTokenResponse } from '../../../services/data/data.interface';
import { JbdDataService } from '../../../services/data/data.service';
import { JBD_CORE_SCROLL_CONFIG_TO_END } from '@core/shared/config/scroll.config';

@Component({
  selector: 'jbd-app-shared-add-payment-details',
  templateUrl: './add-payment-details.component.html',
  styleUrls: ['./add-payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeToggle],
})
export class JbdSharedAddPaymentDetailsDialogComponent implements OnInit {
  public alert: IJbdAlert = {
    isVisible: false,
    message: '',
    type: 'danger',
    isDismissible: true,
  };
  public isLoading = false;
  public addCardForm!: UntypedFormGroup;
  public expiryField!: IJbdPaymentDetailAddCardField;
  public cvcField!: IJbdPaymentDetailAddCardField;
  public cardNumberField!: IJbdPaymentDetailAddCardField;
  public cardOptions = DEFAULT_STRIPE_CARD_ELEMENT_OPTIONS;
  public elementsOptions = DEFAULT_STRIPE_ELEMENTS_OPTIONS;

  @ViewChild('alertElement', { static: false })
  public alertElement!: ElementRef;
  @ViewChild(StripeCardExpiryComponent)
  public cardExpiry!: StripeCardExpiryComponent;
  @ViewChild(StripeCardNumberComponent)
  public cardNumber!: StripeCardNumberComponent;
  @ViewChild(StripeCardCvcComponent)
  public cardCvc!: StripeCardCvcComponent;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dataService: JbdDataService,
    private dialogRef: MatDialogRef<JbdUiDialogContainerComponent>,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private translocoService: TranslocoService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.expiryField = this.creatCardFieldState();
    this.cardNumberField = this.creatCardFieldState();
    this.cvcField = this.creatCardFieldState();
  }

  private createForm(): void {
    this.addCardForm = this.fb.group({
      isDefault: false,
    });
  }

  private creatCardFieldState(): IJbdPaymentDetailAddCardField {
    return {
      disabled: false,
      invalid: false,
      touched: false,
      message: '',
    };
  }

  private toggleDisableCardField(
    component:
      | StripeCardCvcComponent
      | StripeCardExpiryComponent
      | StripeCardNumberComponent,
    field: IJbdPaymentDetailAddCardField,
    value: boolean
  ) {
    component.update({ disabled: value });
    field.disabled = value;
  }

  public handleInputChange(
    $event:
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardNumberElementChangeEvent,
    field: IJbdPaymentDetailAddCardField
  ): void {
    field.touched = true;
    field.invalid = !!$event.error?.message ?? false;
    field.message = $event.error?.message ?? '';
  }

  @HostListener('document:keydown.enter', ['$event'])
  public onSubmit() {
    let paymentDetailId: string;

    this.expiryField.touched = true;
    this.cvcField.touched = true;
    this.cardNumberField.touched = true;

    this.toggleDisableCardField(this.cardExpiry, this.expiryField, true);
    this.toggleDisableCardField(this.cardNumber, this.cardNumberField, true);
    this.toggleDisableCardField(this.cardCvc, this.cvcField, true);

    this.addCardForm.disable();
    this.isLoading = true;

    this.dataService
      .createPaymentToken()
      .pipe(
        switchMap(({ clientSecret }: IJbdDataPaymentTokenResponse) =>
          this.stripeService.confirmCardSetup(clientSecret, {
            payment_method: {
              card: this.cardNumber.element,
            },
          })
        ),
        switchMap(
          ({
            setupIntent,
            error,
          }: {
            setupIntent?: SetupIntent;
            error?: StripeError;
          }) => {
            if (setupIntent) {
              paymentDetailId = <string>setupIntent!.payment_method ?? '';
            }

            return paymentDetailId
              ? this.dataService.updatePaymentDetail(
                  {
                    isDefault: this.addCardForm.value.isDefault,
                  },
                  { paymentDetailId }
                )
              : throwError(() => error);
          }
        )
      )
      .subscribe({
        next: (paymentDetails: IJbdPaymentDetail[]) =>
          this.handleSuccessResponse(paymentDetails, paymentDetailId),
        error: (error?: HttpErrorResponse | StripeError) =>
          this.handleErrorResponse(error),
      });
  }

  private handleSuccessResponse(
    paymentDetails: IJbdPaymentDetail[],
    paymentDetailId?: string
  ): void {
    const [paymentDetail] = paymentDetails.filter(
      (paymentDetail) => paymentDetail.id === paymentDetailId
    );
    this.dialogRef.close({ type: 'add', data: paymentDetail });
  }

  private handleErrorResponse(error?: HttpErrorResponse | StripeError): void {
    if (!error?.type || error.type !== 'validation_error') {
      this.alert.message = this.translocoService.translate(
        error?.type
          ? 'PAYMENTS.PAYMENT_DETAILS.ALERT.ADD.DANGER'
          : 'UI.SHARED.FORM.ALERT.ERROR.FORMALLY.GENERIC'
      );
      this.alert.type = 'danger';
      this.alert.isVisible = true;
      this.alert.isDismissible = false;
      this.alertElement.nativeElement.scrollIntoView(
        JBD_CORE_SCROLL_CONFIG_TO_END
      );
    }

    this.toggleDisableCardField(this.cardExpiry, this.expiryField, false);
    this.toggleDisableCardField(this.cardNumber, this.cardNumberField, false);
    this.toggleDisableCardField(this.cardCvc, this.cvcField, false);

    this.addCardForm.enable();
    this.isLoading = false;
    this.cdRef.markForCheck();
  }
}
