import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IJbdCustomer } from '../../misc/interfaces/customer.interface';
import { JbdDataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class JbdCustomerService {
  constructor(private dataService: JbdDataService) {}

  public getCustomer(): IJbdCustomer | null {
    const customerString = localStorage.getItem('customer') ?? '';

    if (customerString) {
      return JSON.parse(decodeURIComponent(escape(atob(customerString))));
    }

    return null;
  }

  public setCustomer(customer: IJbdCustomer): void {
    localStorage.setItem(
      'customer',
      btoa(unescape(encodeURIComponent(JSON.stringify(customer))))
    );
  }

  public clearCustomer(): void {
    localStorage.removeItem('customer');
  }

  public loadCustomer(): Observable<IJbdCustomer> {
    return this.dataService
      .getCustomer()
      .pipe(tap((customer) => this.setCustomer(customer)));
  }

  public isCustomerProfileFilled(): boolean {
    return (
      this.getCustomer()?._embedded.attributes.some(
        (attribute) =>
          attribute.key.includes('customerProfileFilled') &&
          JSON.parse(attribute.value)
      ) ?? false
    );
  }

  public isCustomerVerified(): boolean {
    return (
      this.getCustomer()?._embedded.attributes.some(
        (attribute) =>
          attribute.key.includes('verificationComplete') &&
          JSON.parse(attribute.value)
      ) ?? false
    );
  }
}
