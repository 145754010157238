import { JbdAccountActivateComponent } from './activate/activate.component';
import { JbdAccountLoginComponent } from './login/login.component';
import { JbdAccountPageComponent } from './page/page.component';
import { JbdAccountPasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { JbdAccountRegisterComponent } from './register/register.component';
import { JbdSharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { JbdUiLoaderComponent } from '@ui/elements/loader/loader.component';
import { JbdUiButtonPrimaryComponent } from '@ui/elements/button/primary/primary.component';
import { JbdUiButtonSecondaryComponent } from '@ui/elements/button/secondary/secondary.component';
import { JbdUiAlertComponent } from '@ui/elements/alert/alert.component';
import { JbdUiAccountLegalLinksComponent } from '@ui/compositions/modules/account/legal-links/legal-links.component';
import { JbdUiAccountOneTimeLinkWrapperComponent } from '@ui/compositions/modules/account/one-time-link/wrapper/wrapper.component';
import { JbdUiLayoutHeaderComponent } from '@ui/compositions/layout/header/header.component';
import { JbdUiLayoutNotificationBlockComponent } from '@ui/compositions/layout/notification-block/notification-block.component';
import { JbdUiFormFieldInputComponent } from '@ui/compositions/form/field/input/input.component';
import { JbdUiFormFocusFirstInvalidFieldDirective } from '@ui/compositions/form/shared/directives/focus-first-invalid-field/focus-first-invalid-field.directive';
import { JbdUiFormFocusFirstFieldDirective } from '@ui/compositions/form/shared/directives/focus-first-field/focus-first-field.directive';
import { JbdUiFormFieldCheckboxComponent } from '@ui/compositions/form/field/checkbox/checkbox.component';

const IMPORTED_COMPONENTS = [
  JbdUiAccountLegalLinksComponent,
  JbdUiAccountOneTimeLinkWrapperComponent,
  JbdUiAlertComponent,
  JbdUiLoaderComponent,
  JbdUiFormFieldInputComponent,
  JbdUiButtonPrimaryComponent,
  JbdUiLayoutHeaderComponent,
  JbdUiLayoutNotificationBlockComponent,
  JbdUiButtonSecondaryComponent,
];
const IMPORTED_MODULES = [JbdSharedModule];
const ACCOUNT_COMPONENTS = [
  JbdAccountActivateComponent,
  JbdAccountPageComponent,
  JbdAccountLoginComponent,
  JbdAccountPasswordForgottenComponent,
  JbdAccountRegisterComponent,
];

@NgModule({
  imports: [
    ...IMPORTED_MODULES,
    ...IMPORTED_COMPONENTS,
    JbdUiFormFocusFirstInvalidFieldDirective,
    JbdUiFormFocusFirstFieldDirective,
    JbdUiFormFieldCheckboxComponent,
  ],
  declarations: [...ACCOUNT_COMPONENTS],
  exports: [...ACCOUNT_COMPONENTS],
})
export class JbdAccountModule {}
