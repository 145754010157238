import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';

export const DEFAULT_STRIPE_CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      fontWeight: '400',
      fontFamily:
        '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.3)',
        fontWeight: 600,
      },
    },
    invalid: {
      color: '#e53935',
    },
  },
};

export const DEFAULT_STRIPE_ELEMENTS_OPTIONS: StripeElementsOptions = {
  locale: 'de',
};
