import { Injectable } from '@angular/core';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'any',
})
export class JbdWebsocketService {
  private eventSource!: EventSource;

  private socketEvents = new Subject<Event | MessageEvent>();
  private socketEvents$ = this.socketEvents.asObservable();

  constructor(private userEventService: JbdCoreUserEventService) {
    this.onLogout();
  }

  public createChannel(topic: string) {
    this.eventSource = this.createEventSource(topic);

    this.subscribeToChannel();
  }

  private subscribeToChannel() {
    this.eventSource.onopen = (event: Event) => this.setEvent(event);
    this.eventSource.onmessage = (event: MessageEvent) => this.setEvent(event);
    this.eventSource.onerror = (event: Event) => this.setEvent(event);
  }

  private createEventSource(topic: string): EventSource {
    return new EventSource(
      `${environment.websockets.source}?topic=${encodeURIComponent(topic)}`,
      {
        withCredentials: true,
      }
    );
  }

  public closeEventSource(): void {
    this.eventSource?.close();
  }

  private onLogout(): void {
    this.userEventService.onLogout().subscribe(() => this.closeEventSource());
  }

  private setEvent(event: Event | MessageEvent): void {
    this.socketEvents.next(event);
  }

  public onEventUpdate(): Observable<Event | MessageEvent> {
    return this.socketEvents$;
  }
}
