import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { JbdCoreAddressTypeEnum } from '@core/shared/misc/enums/address-type.enum';
import { IJbdEmbeddedAddress } from '@core/shared/misc/interfaces/adress.interface';
import {
  NO_DIGITS_VALIDATOR_PATTERN,
  ZIP_VALIDATOR_PATTERN,
} from '@core/utils/validators/pattern.validators';
import { map, Observable, ObservedValueOf, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { JbdCustomerService } from '../customer/customer.service';
import { JbdDataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class JbdAddressService {
  constructor(
    private dataService: JbdDataService,
    private fb: FormBuilder,
    private customerService: JbdCustomerService
  ) {}

  public getAddresses(): IJbdEmbeddedAddress[] {
    return this.customerService.getCustomer()?._embedded?.addresses ?? [];
  }

  public getAddress(addressId: string): IJbdEmbeddedAddress | undefined {
    const addresses = this.getAddresses();

    return addresses.find(({ id }) => id === addressId) || undefined;
  }

  public getAddressesByType(
    filterByType: JbdCoreAddressTypeEnum
  ): IJbdEmbeddedAddress[] {
    const addresses = this.getAddresses();

    return addresses.filter(({ type }) => type === filterByType);
  }

  public addAddress(
    address: IJbdEmbeddedAddress
  ): Observable<IJbdEmbeddedAddress[]> {
    return this.dataService
      .addAddress(address)
      .pipe(this.forceCustomerUpdate());
  }

  public deleteAddress(addressId: string): Observable<IJbdEmbeddedAddress[]> {
    return this.dataService
      .deleteAddress({ addressId })
      .pipe(this.forceCustomerUpdate());
  }

  public updateAddress(
    params: IJbdEmbeddedAddress,
    addressId: string
  ): Observable<IJbdEmbeddedAddress[]> {
    return this.dataService
      .updateAddress(params, { addressId })
      .pipe(this.forceCustomerUpdate());
  }

  private forceCustomerUpdate(): OperatorFunction<
    IJbdEmbeddedAddress[],
    ObservedValueOf<Observable<IJbdEmbeddedAddress[]>>
  > {
    return switchMap((addresses) =>
      this.customerService.loadCustomer().pipe(map(() => addresses))
    );
  }

  public createForm(type = JbdCoreAddressTypeEnum.INVOICE): FormGroup {
    return this.fb.nonNullable.group({
      addressee: ['', Validators.required],
      description: '',
      isDefault: false,
      type,
      address: this.fb.nonNullable.group({
        street: ['', Validators.required],
        houseNo: ['', Validators.required],
        zip: [
          '',
          [Validators.required, Validators.pattern(ZIP_VALIDATOR_PATTERN)],
        ],
        city: [
          '',
          [
            Validators.required,
            Validators.pattern(NO_DIGITS_VALIDATOR_PATTERN),
          ],
        ],
        country: 'DE',
      }),
    });
  }

  public patchForm(
    address: IJbdEmbeddedAddress,
    control: AbstractControl
  ): void {
    control.patchValue(address);
    control.updateValueAndValidity();
  }
}
