import { JbdNavigationPageComponent } from './page/page.component';
import { JbdSharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { JbdNavigationBadgeComponent } from './page/badge/badge.component';
import { JbdUiButtonPrimaryComponent } from '@ui/elements/button/primary/primary.component';

const IMPORTED_COMPONENTS = [JbdUiButtonPrimaryComponent];

const IMPORTED_MODULES = [JbdSharedModule];
const NAVIGATION_COMPONENTS = [
  JbdNavigationBadgeComponent,
  JbdNavigationPageComponent,
];

@NgModule({
  imports: [...IMPORTED_MODULES, ...IMPORTED_COMPONENTS],
  declarations: [...NAVIGATION_COMPONENTS],
})
export class JbdNavigationModule {}
