import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jbd-app-navigation-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdNavigationBadgeComponent {
  @Input() public count!: number;
}
