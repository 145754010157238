export enum JbdCoreOptionKeyEnum {
  VAT = 'option.vat.de',
  ATTENTION = 'option.attention.value',
  ORDER_MIN_LEAD_TIME = 'option.order.minimum_lead_time',
  ORDER_MAX_LEAD_TIME = 'option.order.maximum_lead_time',
  ORDER_DEFAULT_LENGTH = 'option.order.default.length',
  ORDER_DEFAULT_BUDGET = 'option.order.default.attention',
  ORDER_LENGTH_OF_STAY = 'option.order.average.length_of_stay',
  ORDER_MAXIMUM_RESERVATION_TIME = 'option.order.maximum_reservation_time',
  ORDER_MIN_DAYS_FLYER_UPLOAD_BEFORE_ORDER_BEGIN = 'option.order.min_days_flyer_upload_before_order_begin',
}
